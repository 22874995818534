import { getDefaultLayoutProps } from '@ori-appshell/layouts/server';
import type { UserSegmentationQueryParams } from '@ori-appshell/rendering';
import { parseTenantLanguageParams, parseUserSegmentationParams } from '@ori-appshell/rendering';
import type { ProductListingPageQuery } from '@ori/editorial-fetching';
import { EditorialChannelType } from '@ori/graphql-schema';
import type { TranslationsContext } from '@ori/i18n';
import { olapicDefaultTranslations, olapicTranslationsNamespace } from '@ori/olapic-lib';
import {
  getProductListingData,
  ProductListingPageContainer,
  type ProductListingPageContainerProps,
  translationsNamespace,
  defaultTranslations,
} from '@ori/product-listing';
import type { GetStaticPaths, GetStaticProps, GetStaticPropsResult, NextPage } from 'next';
import { PageFeatures } from '~/common-data/PageFeatures';
import {
  ALLOWED_PRODUCT_LISTING_PAGE_TYPES,
  APP_NAME,
  REVALIDATE_INTERVAL_IN_SECONDS,
  SERVER_PAGINATION_LIMIT_ROWS,
} from '~/constants';
import { defaultNamespacedTranslations } from '~/constants/translationKeys';
import type { LayoutProps } from '~/containers/Layout';
import { Layout } from '~/containers/Layout';
import { productListingMainLogger } from '~/logger';
import { getStaticPropsError } from '~/utils';

type PageQueryParams = UserSegmentationQueryParams & {
  // First element is the listing type, the rest are the content path
  // listing type: plp | category | brands | outlet | sample-shop | new
  // content path: 2023-summer-wow | skincare/new | man/skin/yolo
  listingType: string[];
};

const listingTypeTranslations = {
  [translationsNamespace]: defaultTranslations,
  [olapicTranslationsNamespace]: olapicDefaultTranslations,
  ...defaultNamespacedTranslations,
};
type ListingTypeTranslations = typeof listingTypeTranslations;

interface ProductListingPageProps
  extends Pick<ProductListingPageContainerProps, 'atpClusterId' | 'customerTypeId' | 'hideRating'>,
    Required<Pick<TranslationsContext, 'language'>> {
  layoutPage: LayoutProps<ListingTypeTranslations>;
  data: ProductListingPageQuery;
  pageId: string;
}

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
});

const logger = productListingMainLogger.createChild('getStaticProps');

export const getStaticProps: GetStaticProps<ProductListingPageProps, PageQueryParams> = async ({ params }) => {
  const { language, tenant } = parseTenantLanguageParams(params);
  const { atpClusterId, customerTypeId } = parseUserSegmentationParams(params);
  const { listingType } = params ?? {};
  // get count from query as string
  const pageType = listingType?.[0];
  const queryPath = listingType?.slice(1);

  if (queryPath && pageType && ALLOWED_PRODUCT_LISTING_PAGE_TYPES.includes(pageType) && queryPath.length > 0) {
    try {
      const { servicesUrls, frontendToken, layoutPage } = await getDefaultLayoutProps<ListingTypeTranslations>({
        language,
        pageId: queryPath.join('/'),
        tenant,
        sourceAppName: APP_NAME,
        defaultTranslations: listingTypeTranslations,
        pageFeatures: Object.values(PageFeatures),
      });

      const { data, hideRating } = await getProductListingData({
        atpClusterId,
        language,
        pageId: queryPath.join('/'),
        tenant,
        limit: SERVER_PAGINATION_LIMIT_ROWS,
        token: frontendToken,
        graphQlUrl: servicesUrls.graphQl.baseUrl,
        staticApiUrl: servicesUrls.staticApi.baseUrl,
        channelType: EditorialChannelType.Web,
        customerTypeId,
        applicationName: APP_NAME,
      });

      const resultProps: GetStaticPropsResult<ProductListingPageProps> = {
        props: {
          layoutPage,
          atpClusterId,
          customerTypeId,
          data,
          hideRating,
          language,
          pageId: queryPath.join('/'),
        },
        revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2,
      };

      return resultProps;
    } catch (error) {
      throw new Error(getStaticPropsError(logger, error));
    }
  }

  return {
    notFound: true,
  };
};

const Page: NextPage<ProductListingPageProps> = ({
  layoutPage,
  atpClusterId,
  customerTypeId,
  data,
  pageId,
  language,
  hideRating,
}) => (
  <Layout layoutPage={layoutPage}>
    <ProductListingPageContainer
      atpClusterId={atpClusterId}
      customerTypeId={customerTypeId}
      hideRating={hideRating}
      data={data}
      pageId={pageId}
      language={language}
    />
  </Layout>
);

export default Page;
